 @font-face {
   font-family: 'Font Awesome';
   font-style: normal;
   font-weight: 400;
   font-display: block;
   src: url("../../../node_modules/@fortawesome/fontawesome-free/webfonts/fa-regular-400.eot");
   src: url("../../../node_modules/@fortawesome/fontawesome-free/webfonts/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("../../../node_modules/@fortawesome/fontawesome-free/webfonts/fa-regular-400.woff2") format("woff2"), url("../../../node_modules/@fortawesome/fontawesome-free/webfonts/fa-regular-400.woff") format("woff"), url("../../../node_modules/@fortawesome/fontawesome-free/webfonts/fa-regular-400.ttf") format("truetype"), url("../../../node_modules/@fortawesome/fontawesome-free/webfonts/fa-regular-400.svg#fontawesome") format("svg"); }
 
 .far {
   font-family: 'Font Awesome';
   font-weight: 400; }
 @font-face {
   font-family: 'Font Awesome';
   font-style: normal;
   font-weight: 900;
   font-display: block;
   src: url("../../../node_modules/@fortawesome/fontawesome-free/webfonts/fa-solid-900.eot");
   src: url("../../../node_modules/@fortawesome/fontawesome-free/webfonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("../../../node_modules/@fortawesome/fontawesome-free/webfonts/fa-solid-900.woff2") format("woff2"), url("../../../node_modules/@fortawesome/fontawesome-free/webfonts/fa-solid-900.woff") format("woff"), url("../../../node_modules/@fortawesome/fontawesome-free/webfonts/fa-solid-900.ttf") format("truetype"), url("../../../node_modules/@fortawesome/fontawesome-free/webfonts/fa-solid-900.svg#fontawesome") format("svg"); }
 
 .fa,
 .fas {
   font-family: 'Font Awesome';
   font-weight: 900; }
 
