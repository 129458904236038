@import 'reset-styles.css';

html,
body {
  width: 100vw;
  font-size: 16px;
  min-height: 100vh;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
  display: contents;
}

html {
  height: auto;
}

body {
  height: 100%;
}

button {
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
  font-size: 1rem;
}

